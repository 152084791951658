// Styles:
import styles from './Header.module.css'

let handleLogout = (event) => {
  localStorage.clear()
}

function Header() {
  return (
    <div className={styles.Header}>
      <div className={styles.Navbar}>
        <div href="https://conectatrampo.com/"  className="Title">
          <h1 className={styles.Title}>
            <a href="https://conectatrampo.com/" target={'blank'}>conecta_ <p className={styles.TitleParagraph}>trampo</p></a>
          </h1>
        </div>
        <div className={styles.Options}>
          <a href={`/dashboard/${localStorage.getItem('userdisplayname')}`} className={styles.OptionsItem}>Currículos</a>
          <a href={`/jobs/${localStorage.getItem('userdisplayname')}`} className={styles.OptionsItem}>Vagas</a>
          <a href="https://conecta.conectatrampo.com/lista" target={'blank'}className={styles.OptionsItem}>Contratados</a>

          { 
            localStorage.getItem('userdisplayname')
            ?
              <a href={`/profile/${localStorage.getItem('userdisplayname')}`} className={styles.OptionsItem}>
                Perfil de {localStorage.getItem('userdisplayname')}
              </a>
            :
              <a href="/login" className={styles.RegisterButton}>
                 <button className={styles.Register}>Login</button>
              </a>
          }

          { 
            localStorage.getItem('token')
            ?
              <a href="/login" className={styles.RegisterButton} onClick={handleLogout}>
                <button className={styles.Register}>Logout</button>
              </a>
            :
              <a href="https://conectatrampo.com/cadastre-se" target={'blank'}className={styles.Cadastro}>Cadastro</a>
          }
        </div>
      </div>
      <div className={styles.Slogan}>
        <span>A plataforma de empregabilidade da Rede Gerando Falcões</span>
      </div>
    </div>
  )
}

export default Header
