// React:
import React from 'react' // React

// Styles:
import styles from './Login.module.css'

// Router:
import { Redirect } from '@reach/router'

// Axios API:
import api from '../../services/api'

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            userdisplayname: '',
            useremail: '',
            password: '',
            loggedin: false,
            token: '',
            loading: false,
            error: ''
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()

        //const siteURL = 'https://sandbox.bazargf.com.br/wp-json/jwt-auth/v1/token'
        //const siteURL  = "https://sandbox.bazargf.com.br"
        const siteURL = "https://conectatrampo.com"

        const loginData = {
            username: this.state.username,
            password: this.state.password
        }

        this.setState({ loading: true }, () => { 
            api.post(`${siteURL}/wp-json/jwt-auth/v1/token`, loginData)
            .then(response => { 
                console.warn(response.data)

                if(!response.data.token) {
                    this.setState({ error: response.data.message, loading: false })
                    return
                }

                localStorage.setItem('token', response.data.token)
                localStorage.setItem('userdisplayname', response.data.user_display_name)

                this.setState({
                    userdisplayname: response.data.user_display_name,
                    useremail: response.data.user_email,
                    loggedin: true,
                    token: response.data.token,
                    loading: false
                })
            })
            .catch(error => this.setState({ error: error.response.data, loading: false }))
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        const { userdisplayname, password, loggedin } = this.state
        const user = userdisplayname ? userdisplayname : localStorage.getItem('userdisplayname')

        if(loggedin || localStorage.getItem('token')) {
            return (
                <Redirect to={`/`} />
            )
        }
        else {
            return (
                <div className={styles.LoginContainer}>
                    <div className={styles.Logo}>
                        <img src="https://sandbox.bazargf.com.br/wp-content/uploads/2021/09/logo-1.png" alt="logo-footer"></img>
                    </div>
                    <div className={styles.Login}>
                        <form onSubmit={this.handleSubmit} className={styles.LoginForm}>
                                <label className={styles.UserInput}>
                                    Usuário:
                                    <input type="text" className="form-control" name="username" value={ user } onChange={this.handleChange} />
                                </label>
                                <label className={styles.PasswordInput}>
                                    Senha:
                                    <input type="password" className="form-control" name="password" value={ password } onChange={this.handleChange} />
                                </label>
                                <button type="submit" className={styles.LoginButton}> Entrar </button>
                        </form>
                    </div>
                </div>
            )
        }
    }
}

export default Login
