// React:
import React, { useEffect, useState } from 'react'
import { Redirect } from '@reach/router'

// Styles:
import styles from './Jobs.module.css'

// Importing MDBReact DataTable and stlying files:
import { MDBDataTableV5 } from 'mdbreact'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.css'
import 'mdbreact/dist/css/mdb.css'

// Importing api URL from api.js and "unidades" from unidades.js:
import api from '../../services/api'

// Importing LoadingSpinner component:
import Loading from '../LoadingSpinner/Loading'

function Dashboard() {
  // Setting states for the Dashboard data:
  const [city, setCity] = useState(null)
  const [cities, setCities] = useState([])
  const [posts, setPosts] = useState([])
  const [activeJobs, setActiveJobs] = useState(0)
  const [companies, setCompanies] = useState(0)
  const [detailed, setDetailed] = useState(false)

  // Loading state:
  const [loading, setLoading] = useState(false)

  // Dashboard Bar Data:
  const jobsNumber = activeJobs
  const companiesNumber = companies

  // Table Columns:
  const [tableData, setTableData] = useState({
    columns: [
      { label: 'Cidade', field: 'cityName' },
      { label: 'Região', field: 'shortRegion' },
      { label: 'Empresa', field: 'companyname' },
      { label: 'Título', field: 'title', },
      { label: 'Área', field: 'cat_title' },
      { label: 'Salário', field: 'salaryrange' },
      { label: 'Descrição', field: 'description' },
      { label: 'Requisitos', field: 'qualifications' },
      { label: 'Data de Criação', field: 'created' },
      { label: 'Mais Detalhes', field: 'button' }
    ]
  })

  // - Managing our data states with useEffect() -
  /*
  // Getting Cities list:
  useEffect(() => {
    api.get(`/vagasAtivasCidadeEstado`).then(resposta => {
        setCities(resposta.data.map(job => {
          return { cidade: job.cityName }
        }))
    }).catch(erro => console.log(erro))
  }, [])
  */

  // Getting Cities list:
  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/vagasAtivasCidadeEstado`)
      setLoading(true)
      setCities(response.data.map(job => {
        return { cidade: job.cityName, estado: job.shortRegion }
      }))
    }

    fetchData().catch(erro => console.log(erro))
    setLoading(false)
  }, [])

  // Getting the City from the localStorage (first time the user loads the page) and then, all resumes from current City:
  useEffect(() => {
    city && localStorage.setItem('city', city)
    const cityLocal = localStorage.getItem('city')
    //const unitLocal = unit
    setCity(cityLocal)

    if (cityLocal) {
      api.get(`/vagasAtivasCidadeEstado/${cityLocal}`).then(resposta => {
          setPosts(resposta.data)
      }).catch(erro => console.log(erro))
    }
  }, [city])

  // Getting all jobs:
  useEffect(() => {
    api.get(`/vagasAtivasCidadeEstado`).then(response => {
      setActiveJobs(response.data.length)
    }).catch(error => console.log(error))
  }, [city])

  // Getting all companies:
  useEffect(() => {
    api.get(`/empresas`).then(response => {
        setCompanies(response.data.length)
    }).catch(error => console.log(error))
  }, [city])

  // Rendering 'posts' data in the table:
  useEffect(() => {
    // Setting the table data:
    const formattedPosts = posts.map(post => ({
      cityName: `${city ? city : post.cityName}`,
      shortRegion: post.shortRegion,
      title: post.title.toUpperCase(),
      companyname: post.companyname,
      salaryrange: post.salaryrangestart
                  ?
                  (
                    // Verify if the value inside the string is a number:
                    /^-?\d+$/.test(post.salaryrangestart)
                    ?
                    (
                      post.salaryrangeend
                      ? `R$${post.salaryrangestart},00 - R$${post.salaryrangeend},00`
                      : `R$${post.salaryrangestart},00`
                    )
                    :
                    post.salaryrangestart[0].toUpperCase() + post.salaryrangestart.slice(1)
                  )
                  : 'Não informado',
      cat_title: post.cat_title.toUpperCase(),
      // Formatting maximum text for Description:
      description: 
        <div style={{ maxHeight:"106px", backgroundColor:"", overflowY:"scroll" }}>
          {
            ((post.description && detailed) ?
            post.description
              .replace(/\r\n/g, "")
              .replace(/&nbsp;/g, "")
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/●/g, "")
            :
            post.description
              .replace(/\r\n/g, "")
              .replace(/&nbsp;/g, "")
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/●/g, "")
              .length < 2000 ?
              `${post.description}`
                .replace(/\r\n/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/<\/?[^>]+(>|$)/g, "")
                .replace(/●/g, "") :
              `${post.description}`
                .replace(/\r\n/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/<\/?[^>]+(>|$)/g, "")
                .replace(/●/g, "")
                .slice(0, 200) 
                + "...")
            || 'Não informado'
          }
        </div>,
      // This field will not be rendered in the table, it will be used for the "Search" field in the table:
      pseudoDescription: post.description,
      // Formatting maximum text for Qualifications:
      qualifications: 
        <div style={{ maxHeight:"106px", backgroundColor:"", overflowY:"scroll" }}>
          {
            ((post.qualifications && detailed) ?
            post.qualifications
              .replace(/\r\n/g, "")
              .replace(/&nbsp;/g, "")
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/●/g, "") 
            :
            post.qualifications
              .replace(/\r\n/g, "")
              .replace(/&nbsp;/g, "")
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/●/g, "")
              .length < 2000 ?
              `${post.qualifications}`
                .replace(/\r\n/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/<\/?[^>]+(>|$)/g, "")
                .replace(/●/g, "") :
              `${post.qualifications}`
                .replace(/\r\n/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/<\/?[^>]+(>|$)/g, "")
                .replace(/●/g, "")
                .slice(0, 200) 
                + "...")
            || 'Não informado'
          }
        </div>,
      // This field will not be rendered in the table, it will be used for the "Search" field in the table:
      pseudoQualifications: post.qualifications,
      created: post.created.slice(0, 10).replace("-", "/").replace("-", "/").split("/").reverse().join("/"),
      // Creating 'Go to' for Job full view:
      button:
      <a href={`https://conectatrampo.com/home/meu-painel-aluno/job/${post.id}`} className={styles.DetailsButton} target="_blank" rel="noreferrer">
        <button className={styles.Details}> {'>'} </button>
      </a>
    }))

    // Creating the table:
    setTableData(s => ({
      ...s,
      rows: formattedPosts
    }))
  }, [city, detailed, posts]);

  let handleDetailed = (event) => {
    if(!detailed) setDetailed(true)
    else setDetailed(false)
  }

  let handleDuplicatedCities = (cities) => {
    //let unique = [];
    /*
    for(let i = 0; i < cities.length; i++){ 
        for(let j = 1; j < cities.length; j++){
            if(cities[i].cidade === cities[j].cidade){
                cities.splice(j, 1)
            }
        }
    }
    */

    for(let i = 0; i < cities.length; i++){ 
      if(!cities[i].cidade) cities.splice(i, 1)
    }

    for(let i = 0; i < cities.length; i++){ 
      for(let j = i + 1; j < cities.length; j++){
          if(cities[i].cidade === cities[j].cidade){
              cities.splice(i, 1)
          }
      }
    }

    for(let i = 0; i < cities.length; i++){ 
      for(let j = i + 1; j < cities.length; j++){
          if(cities[i].cidade > cities[j].cidade){
              let aux = cities[i].cidade
              cities[i].cidade = cities[j].cidade
              cities[j].cidade = aux
          }
      }
    }

   return cities
  }

  const user = localStorage.getItem('userdisplayname')

  if(!localStorage.getItem('token')) {
    return (
        <Redirect to={`/login`} />
    )
  }
  else {
    return (
      <div className={styles.Dashboard}>
        {/* User Name */}
        <div className={styles.UserDisplayName}>
          <span> Bem vindo(a) de volta, {user}! </span>
        </div>
  
        {/* City Title */}
        { city && <div className={styles.CityTitle}> {!loading ? "Carregando..." : city} </div> }
        
        {/* City Title Underline */}
        { city && <div className={styles.CityTitle_Underline}/> }

        {
          !loading ? <Loading />
          :
          <>
            {/* Dashboard Bar */}
            <div className={styles.Bar}>
              <div className={styles.Informations}>
                  <div className={styles.square1}>
                    <span>
                      Vagas
                      <p>{jobsNumber}</p>
                    </span>
                  </div>
                  <div className={styles.square2}>
                    <span>
                      Empresas
                      <p>{companiesNumber}</p>
                    </span>
                  </div>
              </div>
            </div>
      
            {/* Resume Container */}  
            <div className={styles.ListContainer}>
              <label> Selecione a Cidade: </label>
      
              {/* Select City */}
              <select required id="Category" className={styles.ListSelect} name="Category" value={city} onChange={event => setCity(event.target.value === "Todas as vagas" ? '' : event.target.value)}>
                <option> {console.log(cities)} </option>
                { handleDuplicatedCities(cities).map(cidadeObj => <option value={cidadeObj.cidade}>{cidadeObj.cidade}</option>) }
              </select>
      
              {/* Resumes Table */}
              <div className={styles.ListTable}>
                <MDBDataTableV5
                  fullPagination
                  infoLabel={['', '-', 'de', '']}
                  noRecordsFoundLabel="Nenhum currículo foi encontrado."
                  searchLabel="Procurar"
                  small
                  sortable={false}
                  entriesOptions={[6, 12, 24]}
                  entries={6}
                  displayEntries={true}
                  pagesAmount={4}
                  data={tableData}
                  entriesLabel="Registros por página"
                  searchBottom
                  striped
                  bordered
                  responsiveSm
                  //scrollX
                  //scrollY
                  //maxHeight="460px"
                />
              </div>

              {/* Toggle Button for Detailed View */}
              { detailed && <button className={styles.Button} onClick={handleDetailed}> {detailed ? 'Menos Detalhes' : 'Mais Detalhes'} </button> }

            </div>
          </>
        }
      </div>
    )
  }
}

export default Dashboard
