// Styles:
import styles from './Home.module.css'
import Faq from '../Faq/Faq'

function Home() {

  return (
    <div className={styles.Home}>
      <div className={styles.Container}>

        {/* Image*/}

        <div className={styles.Box}>
          <span className={styles.Line}>DashBoard do <a href="https://conectatrampo.com/" target={'blank'} className={styles.Conecta}>conecta_</a></span>
          <div className={styles.Img}><img src="https://sandbox.bazargf.com.br/wp-content/uploads/2022/08/peeps2.png" alt="companies-div" ></img></div>
        </div>

          {/* Text */}

        <div className={styles.Phrase}>
          <p >Essa é uma ferramenta que apoia líderes sociais e agentes a terem visibilidade de oportunidades e possíveis candidatos, possibilitando trabalhar ativamente a empregabilidade nos territórios. <br />
            Além disso, funciona também como um adicional na estratégia de marketing para conseguir novas parcerias.</p>
          <div className={styles.About}>
            <h3 className={styles.Title}> FAQ</h3>
            <div className={styles.Subtitle}> Dúvidas sobre o Dashboard</div>
            <div className={styles.Seta}><img src="https://i.ibb.co/hZvFd3f/seta.png" alt="seta" width={25} height={20}></img></div>
          </div>
        </div>

      </div>
      <Faq /> 
    </div>
  )
}

export default Home
