// React:
import React from "react"

// Styles:
import styles from './Loading.module.css'

function Loading() {
  return (
    <div className={styles.SpinnerContainer}>
      <div className={styles.LoadingSpinner}/>
    </div>
  )
}

export default Loading
