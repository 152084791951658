// Styles:
import styles from './Faq.module.css'
import { useState } from 'react';



const Faq = () => {

    const Login = [
        {
            question: "Como acessar o Dashboard? ",
            answer:
                "Selecione “Login” no menu superior desta página e digite o mesmo usuário e senha que utiliza no Conecta Trampo.",

        },
        {
            question: "Consigo acessar o Dashboard sem cadastro no Conecta? ",
            answer: <span>
                Não. Caso a ONG não tenha criado uma conta no Conecta, <a href='https://conectatrampo.com/cadastre-se/ ' target={"black"}>clique aqui</a> e preencha o formulário de título "Unidade". <br />
                Feito o cadastro, retorne nesta página e acesse-a conforme orientado na etapa anterior.
            </span>
        },

    ];

    const Resumes = [
        {
            question: "Como visualizar os currículos dos atendidos?",
            answer:
                "Selecione “Currículos” no menu superior e filtre pelo nome de sua unidade, a página retornará com o resumo de informações preenchidas em cada currículo (similar a página de Histórico de contratados). Para acessar o currículo completo selecione “mais detalhes”. ",

        },
        {
            question: "Por que não consigo visualizar o currículo completo?",
            answer: <span>
                A opção "mais detalhes" acessa o Painel Empresa do Conecta Trampo, sendo assim, a unidade deve estar logada na função empregador.
                Caso tenha dúvidas sobre como trocar a função <a href='https://conectatrampo.com/passo-a-passo-unidade/' target={'blank'}>clique aqui</a> e assista o primeiro vídeo da página Tutorial – Unidade.
            </span>
        },
        {
            question: "O que são esses números nos cards?  ",
            answer:
                "São cards dinâmicos que retornam o número total de currículos na plataforma, pessoas contratadas e currículos que se candidataram a vagas, filtrando apenas candidatos vinculados ao nome da unidade selecionada. "

        },


    ];

    const Jobs = [
        {
            question: "Como faço para visualizar as vagas em minha cidade? ",
            answer:
                "Selecione “Vagas” no menu superior e filtre pelo nome da cidade desejada, a página retornará com o resumo de informações preenchidas em cada vaga. Para acessar o conteúdo completo da vaga selecione “mais detalhes”. ",

        },
        {
            question: "Por que não localizo o nome de minha cidade na lista? ",
            answer:
                "O filtro conterá apenas os nomes das cidades que possuem alguma vaga. Caso não localize sua cidade, não se preocupe, assim que uma vaga for publicada para sua cidade o nome será inserido no campo para ser filtrado.",
        },
        {
            question: "O que são esses números nos cards? ",
            answer:
                "O card “vagas” retorna o número de vagas disponíveis no Conecta Trampo. O card “empresas” soma o número total de empresas cadastradas no Conecta Trampo (com ou sem vaga publicada). ",
        },

    ];

    const Accordion = ({ question, answer }) => {
        const [isActive, setIsActive] = useState(false);

        return (
            <div>
                <div className={styles.Question} onClick={() => setIsActive(!isActive)}>
                    <div>{question}</div>
                    <div className={styles.Icon}>{isActive ? '-' : '+'}</div>
                </div>
                {isActive && <div className={styles.Answer}>{answer}</div>}
            </div>
        );
    };


    return (
            <div className={styles.Container}>

                <div className={styles.Box}>
                    <div className={styles.Title}>Login</div>
                    {Login.map(({ question, answer }) => (
                        <Accordion question={question} answer={answer} />
                    ))}
                </div>

                <div className={styles.Box}>
                    <div className={styles.Title}>Currículos</div>
                    {Resumes.map(({ question, answer }) => (
                        <Accordion question={question} answer={answer} />
                    ))}
                </div>


                <div className={styles.Box}>
                    <div className={styles.Title}>Vagas</div>
                    {Jobs.map(({ question, answer }) => (
                        <Accordion question={question} answer={answer} />
                    ))}
                </div>
            </div>
    )
};

export default Faq;