// React:
import React, { useEffect, useState } from 'react'
import { Redirect } from '@reach/router'

// Styles:
import styles from './Dashboard.module.css'

// Importing MDBReact DataTable and stlying files:
import { MDBDataTableV5 } from 'mdbreact'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.css'
import 'mdbreact/dist/css/mdb.css'

// Importing api URL from api.js and "unidades" from unidades.js:
import api from '../../services/api'
import unidades from '../../Unidades/unidades.json'

// Importing LoadingSpinner component:
import Loading from '../LoadingSpinner/Loading'

function Dashboard() {
  // Setting states for the Dashboard data:
  const [unit, setUnit] = useState(null)
  const [city, setCity] = useState(null)
  const [cities, setCities] = useState([])
  const [filter, setFilter] = useState('city')
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [hirings, setHirings] = useState(0)
  const [appliedResumes, setAppliedResumes] = useState(0)

  // Loading state:
  const [loading, setLoading] = useState(false)

  // Dashboard Bar Data:
  const resumesNumber = unit ? posts.length : "-"
  const hiringsNumber = unit ? hirings : "-"
  const appliedResumesNumber = unit ? appliedResumes : "-"

  // Table Columns:
  const [tableData, setTableData] = useState({
    columns: [
      { label: 'Unidade', field: 'unit' },
      { label: 'Candidato', field: 'name', },
      { label: 'Gênero', field: 'gender' },
      { label: 'E-mail', field: 'email' },
      { label: 'Telefone', field: 'phone' },
      { label: 'Idade', field: 'age' },
      { label: 'Habilidades', field: 'skills' },
      { label: 'Categoria', field: 'jobCategory' },
      //{ label: 'Subcategoria', field: 'jobSubcategory' },
      { label: 'Cargo', field: 'role' },
      //{ label: 'Tipo de Contrato', field: 'jobType' },
      //{ label: 'Pronto para começar', field: 'isAvailable' },
      //{ label: 'LinkedIn', field: 'linkedin' },
      { label: 'Data de Criação', field: 'created' },
      { label: 'Mais Detalhes', field: 'button' }
    ]
  })

  // - Managing our data states with useEffect() -
  // Getting the unit from the localStorage (first time the user loads the page) and then, all resumes from current unit:
  useEffect(() => {
    unit && localStorage.setItem('unit', unit)
    const unitLocal = localStorage.getItem('unit')
    //const unitLocal = unit
    setUnit(unitLocal)

    if (unitLocal) {
        api.get(`/curriculos/${unitLocal}`).then(resposta => {
          setPosts(resposta.data)
        }).catch(erro => console.log(erro))
    }
  }, [unit])

  // Getting the City from the localStorage (first time the user loads the page) and then, all resumes from current City:
  useEffect(() => {
    city && localStorage.setItem('city', city)
    const cityLocal = localStorage.getItem('city')
    //const unitLocal = unit
    setCity(cityLocal)

    if (cityLocal) {
      api.get(`/curriculosPorCidade/${cityLocal}`).then(resposta => {
        setPosts(resposta.data)
      }).catch(erro => console.log(erro))
    }
  }, [city])

  /*
  // Getting Cities list:
  useEffect(() => {
    api.get(`/cidadesCurriculos`).then(resposta => {
      setLoading(true)
      setCities(resposta.data.map(job => {
        return { cidade: job.cityName, estado: job.shortRegion }
      }))
    }).catch(erro => console.log(erro))
    setLoading(false)
  }, [])
  */
  
  // Getting Cities list:
  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/cidadesCurriculos`)
      setLoading(true)
      setCities(response.data.map(city => {
        return { cidade: city.cityName, estado: city.shortRegion }
      }))
    }

    fetchData().catch(erro => console.log(erro))
    setLoading(false)
  }, [])

  // Getting all categories:
  useEffect(() => { 
    api.get('/categorias').then(response => {
      setCategories(response.data)
    }).catch(error => console.log(error))
  }, [unit])
  
  // Getting all hirings:
  useEffect(() => {
    api.get(`/contratados/${unit}`).then(response => {
      setHirings(response.data.length)

      console.log(response.data.length)
    }).catch(error => console.log(error))
  }, [unit])

  // Getting all applied resumes:
  useEffect(() => {
    api.get(`/curriculosAplicados/${unit}`).then(response => {
      setAppliedResumes(response.data[0]?.curriculos_aplicados || 0)
    }).catch(error => console.log(error))
  }, [unit])

  // Getting user age from birth date:
  let calcUserAge = (birthDate) => {
    const yearDifference = new Date().getFullYear() - birthDate?.slice(0, 4)
    const monthDifference = (new Date().getMonth() + 1) - birthDate?.slice(5, 7)
    const dayDifference = new Date().getDate() - birthDate?.slice(8, 10)

    let age = null

    // Birthday hasn't happened yet this year...
    if(monthDifference < 0) age = yearDifference - 1
    else if(monthDifference === 0 && dayDifference < 0) age = yearDifference - 1

    // Already have birthday this year!
    else if(monthDifference === 0 && dayDifference >= 0) age = yearDifference
    else if(monthDifference > 0) age = yearDifference

    const ageMessage = age > 0 ? `${age} anos` : 'Não informada'

    return ageMessage
  }

  // Rendering 'posts' data in the table:
  useEffect(() => {
    /*
    const catSubcat = categories.map((category) => {
      if(category.parentid !== 0) {
        return {
          id: category.id,
          category: categories.find(element => element.id === category.parentid).cat_title,
          parentid: category.parentid,
          subcategory: category.cat_title
        }
      }
  
      return {
        id: category.id,
        category: category.cat_title,
        parentid: 'none',
        subcategory: '-'
      }
    })
    */

    // Setting the table data:
    const formattedPosts = posts.map(post => ({
      //unit: post.unidades ? post.unidades : 'Não informada',
      // Utilizing the substring() method to remove the "UFIELD_..." part of the string:
      // (As soon as the "UFIELD_..." is fixed, this code can be removed and the "unit" property commented above can be used instead).
      unit: post.unidades
            ?
            (
              post.unidades.includes("UFIELD")
              ?
              (
                `${post.unidades}`.substring(0, `${post.unidades}`.indexOf("UFIELD_")).length > 0
                ?
                `${post.unidades}`.substring(0, `${post.unidades}`.indexOf("UFIELD_"))
                :
                'Não informada'
              )
              :
              post.unidades
            )
            :
            'Não informada',
      name: `${post.first_name[0]?.toUpperCase() + post.first_name?.slice(1) + ' ' + post.last_name[0]?.toUpperCase() + post.last_name?.slice(1)}`,
      email: post.email_address?.toLowerCase(),
      phone: post.home_phone?.replace(/[()-]/g, "").replaceAll(" ", "").replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3'),
      gender: `${post.gender}` === '0' ? 'Prefiro não informar' : `${post.gender}` === '1' ? 'M' : `${post.gender}` === '2' ? 'F' : "null",
      age: post.date_of_birth ? calcUserAge(post.date_of_birth) : 'Não informada',
      //birthDate: post.date_of_birth?.slice(0, 10).replace("-", "/").replace("-", "/").split("/").reverse().join("/"),
      skills: post.skills ?
        <div style={{ maxHeight:"88px", overflowY:"scroll" }}>
          { post.skills.toUpperCase() }
        </div>
        :
        "Não informado",
      // This field will not be rendered in the table, it will be used for the "Search" field in the table:
      pseudoSkills: post.skills,
      isAvailable: post.iamavailable ? 'Não' : 'Sim',
      role: post.application_title?.toUpperCase(),
      jobCategory: post.job_category,
      /* 
      // This snippet of code is referred to the above commented method "catSubcat()".
      // It was used to get the subcategory using the array of categories, once category and subcategory are in the same field in the database.
      // If it is necessary to use this method again, uncomment the above "catSubcat()" method and this snippet. Then, comment the previous "jobCategory".
      jobCategory: `${catSubcat.find(element => element.id === post.job_category).category}`,
      jobSubcategory: `${catSubcat.find(element => element.id === post.job_category).subcategory}`,
      */
      jobType: post.jobtype ? post.jobtype : '-',
      linkedin: post.linkedin ? post.linkedin : '-',
      created: post.created?.slice(0, 10).replace("-", "/").replace("-", "/").split("/").reverse().join("/"),
      button:
      <a href={`https://conectatrampo.com/home/meu-painel-empresa/resume/${post.id}`} className={styles.DetailsButton} target="_blank" rel="noreferrer">
        <button className={styles.Details}> {'>'} </button>
      </a>
    }))

    // Creating the table:
    setTableData(s => ({
      ...s,
      rows: formattedPosts
    }))
  }, [unit, categories, posts]);

  const user = localStorage.getItem('userdisplayname')

  if(!localStorage.getItem('token')) {
    return (
        <Redirect to={`/login`} />
    )
  }
  else {
    return (
      <div className={styles.Dashboard}>
        {/* User Name */}
        <div className={styles.UserDisplayName}>
          <span> Bem vindo(a) de volta, {user}! </span>
        </div>
  
        {/* Unit Title */}
        { unit && <div className={styles.UnitTitle}> {!loading ? "Carregando..." : (filter === "unit" ? unit : city)} </div> }
        
        {/* Unit Title Underline */}
        { unit && <div className={styles.UnitTitle_Underline}/> }
  
        {
          !loading ? <Loading />
          :
          <>
            {/* Dashboard Bar */}
            <div className={styles.Bar}>
              <div className={styles.Informations}>
                  <div className={styles.square1}>
                    <span>
                      Currículos
                      <p>{filter === 'city' ? '-' : resumesNumber}</p>
                    </span>
                  </div>
                  <div className={styles.square2}>
                    <span>
                      Contratações
                      <p>{filter === 'city' ? '-' : hiringsNumber}</p>
                    </span>
                  </div>
                  <div className={styles.square3}>
                    <span>
                      CV's Aplicados
                      <p>{filter === 'city' ? '-' : appliedResumesNumber}</p>
                    </span>
                  </div>
              </div>
            </div>
      
            {/* Resume Container */}  
            <div className={styles.ListContainer}>

              {/* Filter checkbox */}
              <div>
                <input type="checkbox" name="filter" value={filter} onChange={event => filter === 'unit' ? setFilter('city') : setFilter('unit')}/>
                <span> Filtro por Unidade </span>
              </div>

              { filter === 'unit' ?
                <>
                  <label> Selecione a Unidade: </label>
        
                  {/* Select Unit */}
                  <select required id="Category" className={styles.ListSelect} name="Category" value={unit} onChange={event => setUnit(event.target.value)}>
                    <option> </option>
                    { unidades.map(unidade => <option value={unidade.unidade}>{unidade.unidade.length < 90 ? unidade.unidade : unidade.unidade.slice(0, 90) + "..."}</option>) }
                  </select>
                </>
                :
                <>
                  <label> Selecione a Cidade: </label>

                  {/* Select City */}
                  <select required id="Category" className={styles.ListSelect} name="Category" value={city} onChange={event => setCity(event.target.value === "Todas as vagas" ? '' : event.target.value)}>
                    { 
                      cities.map(cidadeObj => 
                        cidadeObj.cidade ?
                          <option value={cidadeObj.cidade}>{ `${cidadeObj.estado} - ${cidadeObj.cidade}` }</option>
                          :
                          <></>)
                    }
                  </select>
                </>
              }
      
              {/* Resumes Table */}
              <div className={styles.ListTable}>
                <MDBDataTableV5
                  fullPagination
                  infoLabel={['', '-', 'de', '']}
                  noRecordsFoundLabel="Nenhum currículo foi encontrado."
                  searchLabel="Procurar"
                  small
                  sortable={false}
                  entriesOptions={[10, 20, 30]}
                  entries={10}
                  displayEntries={true}
                  pagesAmount={4}
                  data={tableData}
                  entriesLabel="Registros por página"
                  searchBottom
                  striped
                  bordered
                  responsiveSm
                  //scrollX
                  //maxWidth="18px"
                />
              </div>
            </div>
          </>
        }
      </div>
    )
  }
}

export default Dashboard
