import styles from './Footer.module.css'

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
				<div className={styles.Company}>
					<span>Empresa</span>
					<p className={styles.FooterLinks}>
						Quero Participar
					</p>
					<p className={styles.FooterLinks}>
						Contate-nos
					</p>
				</div>
				<div className={styles.Ongs}>
					<span>Ongs</span>
					<p className={styles.FooterLinks}>
						Quero Participar
					</p>
					<p className={styles.FooterLinks}>
						Contate-nos
					</p>
				</div>
				<div className={styles.Logo}> 
					<a href='https://gerandofalcoes.com/' target={'blank'}><img src="https://sandbox.bazargf.com.br/wp-content/uploads/2021/09/logo-1.png"  alt="logo-footer"></img>
					</a>
				</div>
				<div className={styles.Candidates}>
					<span>Candidatos</span>
					<p className={styles.FooterLinks}>
						Quero Participar
					</p>
					<p className={styles.FooterLinks}>
						Contate-nos
					</p>
				</div>
				<div className={styles.ConectaTrampo}>
					<span className={styles.Conecta}>conecta_ <p className={styles.ConectaParagraph}>trampo</p></span>
					<p className={styles.FooterLinks}>
						Quero Participar
					</p>
					<p className={styles.FooterLinks}>
						Contate-nos
					</p>
				</div>
			</div>
			<div className={styles.Copyright}>
				<p className={styles.CopyrightMessage}>Conecta Trampo | Powered by Gerando Falcões - Copyright © {new Date().getFullYear()}</p>
			</div>
    </div>
  )
}

export default Footer
